import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat } from '@common-src/filter';
import moment, { Moment } from 'moment';

export class ParkingCarInOutRecordEntityModel extends BaseEntityModel {
    carNo: string = undefined;
    inTime: number = undefined;
    inEntityId: string = undefined;
    inEntityName: string = undefined;
    outTime: string = undefined;
    outEntityId: string = undefined;
    outEntityName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '车牌号',
                dataIndex: 'carNo',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '驶入时间',
                dataIndex: 'inTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)}`;
                    }
                    return '-';
                }
            },
            {
                title: '入口设备',
                dataIndex: 'inEntityName'
            },
            {
                title: '驶出时间',
                dataIndex: 'outTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)}`;
                    }
                    return '-';
                }
            },
            {
                title: '出口设备',
                dataIndex: 'outEntityName'
            },
            {
                title: '停车时长',
                dataIndex: 'parkingTime',
                customRender: (text, record, index) => {
                    if (record.outTime && record.inTime) {
                        const duration = moment.duration(record.outTime - record.inTime);
                        let durationText = '';
                        const years = duration.years();
                        const months = duration.months();
                        const days = duration.days();
                        const hours = duration.hours();
                        const minutes = duration.minutes();
                        const seconds = duration.seconds();
                        if (years > 1) {
                            durationText += `${years}年`;
                        }
                        if (months > 1 || durationText.length > 0) {
                            durationText += `${months}月`;
                        }
                        if (days > 1 || durationText.length > 0) {
                            durationText += `${days}天`;
                        }
                        if (hours > 1 || durationText.length > 0) {
                            durationText += `${hours}小时`;
                        }
                        if (minutes > 1 || durationText.length > 0) {
                            durationText += `${minutes}分`;
                        }
                        if (seconds > 1 || durationText.length > 0) {
                            durationText += `${seconds}秒`;
                        }
                        return durationText;
                    }
                    return '-';
                }
            }
            // {
            //     title: '操作',
            //     dataIndex: 'action',
            //     scopedSlots: { customRender: 'action' }
            // }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class ParkingCarInOutRecordQueryModel extends QueryPageModel {
    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        isRange: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    @QueryControl({
        // label: '车牌号',
        placeholder: '请输入车牌号',
        type: QueryControlType.TEXT
    })
    carNo: string = undefined;

    toService() {
        return {
            params: {
                carNo: this.carNo,
                startTime: this.dateRange[0].startOf('day').toDate().getTime(),
                endTime: this.getEndTime(this.dateRange[1])
            }
        };
    }
}
