
import { ParkingCarInOutRecordEntityModel, ParkingCarInOutRecordQueryModel } from '@/pages/sub-system/model/parking-car-in-out-record-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, download } from '@common-src/service/request';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingGate`;

class ParkingCarInOutRecordService implements ICRUDQ<ParkingCarInOutRecordEntityModel, ParkingCarInOutRecordQueryModel> {
    async create(model: ParkingCarInOutRecordEntityModel):Promise<ParkingCarInOutRecordEntityModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<ParkingCarInOutRecordEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ParkingCarInOutRecordEntityModel().toModel(res);
    }

    async update(model: ParkingCarInOutRecordEntityModel):Promise<ParkingCarInOutRecordEntityModel> {
        throw new Error('Not implement.');
    }

    async delete(model: ParkingCarInOutRecordEntityModel):Promise<ParkingCarInOutRecordEntityModel> {
        throw new Error('Not implement.');
    }

    async query(query?: ParkingCarInOutRecordQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/hts/car/records`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        // res.items = [
        //     { id: '1', carNo: '苏E40656', inEntityName: 'A区入口闸机', outEntityName: 'A区出口闸机', inTime: 1634679451425, outTime: 1634688451425 },
        //     { id: '2', carNo: '苏E4PS960', inEntityName: 'B区入口闸机', outEntityName: 'C区出口闸机', inTime: 1634699451425, outTime: 1634788451425 },
        //     { id: '3', carNo: '苏E56985', inEntityName: 'A区入口闸机', outEntityName: 'H区出口闸机', inTime: 1634629451425, outTime: 1634688451425 },
        //     { id: '4', carNo: '苏E89517', inEntityName: 'A区入口闸机', outEntityName: 'A区出口闸机', inTime: 1634619451425, outTime: 1634688451425 },
        //     { id: '5', carNo: '苏E56432', inEntityName: 'C区入口闸机', outEntityName: 'B区出口闸机', inTime: 1634682451425, outTime: 1634688451425 },
        //     { id: '6', carNo: '苏E7136O', inEntityName: 'A区入口闸机', outEntityName: 'H区出口闸机', inTime: 1634695451425, outTime: 1634788451425 },
        //     { id: '7', carNo: '苏EG4568', inEntityName: 'H区入口闸机', outEntityName: 'A区出口闸机', inTime: 1634650451425, outTime: 1634699451425 },
        //     { id: '8', carNo: '苏E2R64P', inEntityName: 'A区入口闸机', outEntityName: 'A区出口闸机', inTime: 1634626451425, outTime: 1634688451425 }
        // ];
        res.items = _.map(res.items, item => item = new ParkingCarInOutRecordEntityModel().toModel(item));
        return res;
    }

    async export(queryModel: ParkingCarInOutRecordQueryModel):Promise<any> {
        const url = `${URL_PATH}/hts/export/car/records`;
        const res = await download(url, queryModel?.toService(), 'post');
        return res;
    }
}

export default new ParkingCarInOutRecordService();
