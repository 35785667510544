










































import { Component } from 'vue-property-decorator';
// import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ParkingCarInOutRecordEntityModel, ParkingCarInOutRecordQueryModel } from './model/parking-car-in-out-record-entity';
import ParkingCarInOutRecordService from './service/parking-car-in-out-record';
import TableComponent from '@common-src/mixins/table-component';

@Component
export default class ParkingCarInOutRecordListComponent extends TableComponent<ParkingCarInOutRecordEntityModel, ParkingCarInOutRecordQueryModel> {
    ParkingCarInOutRecordEntityModel = ParkingCarInOutRecordEntityModel;
    created() {
        this.initTable({
            service: ParkingCarInOutRecordService,
            queryModel: new ParkingCarInOutRecordQueryModel(),
            tableColumns: ParkingCarInOutRecordEntityModel.getTableColumns()
        });
        this.getList();
    }

    exportClick() {
        this.startFullScreenLoading('正在导出...');
        ParkingCarInOutRecordService.export(this.queryModel).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}

